window.ParsleyValidator
.addValidator('urlstrict', function (value, requirement) {
    var regExp = /^(https?|s?ftp|git):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

    return '' !== value ? regExp.test( value ) : false;
}, 32)
.addMessage('en', 'urlstrict', 'Must be a valid URL');

window.Parsley.addValidator('validUsername', {
    requirementType: 'regexp',
    validateString: function(_value, requirement) {
        return '' !== _value ? requirement.test( _value ) : false;
    },
    messages: {
        en: 'For your safety, we do not allow usernames with \'@\' symbols.',
    }
});

window.Parsley.addValidator('maxPostLength', {
  requirementType: 'string',
  validateString: function(_value, requirement) {
       var reg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

       if(reg.test(_value)){
           var array =_value.match(reg);
           console.log('has url');
           console.log(requirement)
           console.log(_value.length)
           console.log(array[0].length)

           if(requirement - ((_value.length - array[0].length) + 23) >= 0) {
             return true;
           }

           return false;

       }else {
         console.log('no-url')
          console.log(requirement)
          console.log(_value.length)
          console.log(requirement - _value.length)

         if((requirement - _value.length) >= 0) {
           return true;
         }

         return false;
       }
  },
  messages: {
    en: 'This value is too long. It should have 280 characters or fewer. Links are counted as 23 characters no matter the length.'
  }
});
